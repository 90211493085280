.galleryContainer {
  position: relative;
}

.galleryContainer img {
  height: 415px;
  width: 100%;
  border-radius: 25px;
}

.arrowContainer {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}

.arrow {
  display: block;
  cursor: pointer;
  padding: 5px;
}

.indexContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
}

/* tablet version */
@media (max-width: 768px) {
  :root {
    --fs-300: 12px;
    --fs-400: 16px;
    --fs-500: 18px;
    --fs-600: 24px;
    --fs-700: 30px;
    --fs-800: 32px;
  }
}

/* phone version */
@media (max-width: 480px) {
  .galleryContainer img {
    height: 255px;
    border-radius: 10px;
  }

  .arrowContainer svg {
    width: 22px;
  }

  .indexContainer {
    display: none;
  }
}
