.dropdownWrap {
  margin-top: 1rem;
}

/* common style for header and box */
.dropdownHeader,
.dropdownBox {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.dropdownHeader {
  background-color: var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  padding: 6px 20px 6px 15px;
  cursor: pointer;
}

/* header title */
.dropdownHeader span {
  font-size: var(--fs-400);
}

.dropdownBox {
  background-color: #f6f6f6;
  color: var(--main-color);
  font-weight: 400;
  padding: 15px 18px;
  border-radius: 0 0 5px 5px;
}

.paragraphe {
  display: block;
  width: 100%;
  word-wrap: break-word;
  font-size: var(--fs-300);
}
