:root {
  --fs-100: 10px;
  --fs-200: 12px;
  --fs-300: 14px;
  --fs-400: 18px;
  --fs-500: 22px;
  --fs-600: 32px;
  --fs-700: 36px;
  --fs-800: 42px;
  --fs-star: 2rem;
  --main-color: #ff6060;
}

/* tablet version */
@media (max-width: 768px) {
  :root {
    --fs-300: 12px;
    --fs-400: 16px;
    --fs-500: 18px;
    --fs-600: 20px;
    --fs-700: 22px;
    --fs-800: 32px;
  }
}

/* phone version */
@media (max-width: 480px) {
  :root {
    --fs-300: 10px;
    --fs-400: 12px;
    --fs-500: 14px;
    --fs-600: 16px;
    --fs-700: 18px;
    --fs-800: 28px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  object-fit: cover;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

@media (max-width: 480px) {
  * {
    font-weight: 400;
  }
}

a.active {
  text-decoration: underline;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role='list'],
ol[role='list'],
li {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

.container {
  margin: 0 2rem;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
  color: var(--main-color);
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  height: auto;
}

main {
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .container {
    margin: 0 1rem;
  }
}
