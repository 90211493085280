.container {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.textWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
}

.error,
.errorMessage,
.linkToHome {
  color: var(--main-color);
}

.error {
  font-size: 200px;
  font-weight: 700;
}

.errorMessage {
  font-size: var(--fs-600);
}

.linkToHome {
  font-size: var(--fs-400);
  position: absolute;
  bottom: 0;
}

/* phone version */
@media (max-width: 480px) {
  .error {
    font-size: 96px;
    font-weight: 700;
  }

  .errorMessage {
    width: 90%;
    text-align: center;
  }
}
