.container {
  width: 100%;
  height: 170px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.logo {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.rights {
  color: #fff;
}
