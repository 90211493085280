.cardContainer {
  width: 100%;
  margin-top: 2rem;
  padding: 3rem;
  background-color: #f6f6f6;
  border-radius: 25px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  gap: 3rem;
  box-shadow: 1px 5px 25px -17px #000;
  -webkit-box-shadow: 1px 5px 25px -17px #000;
  -moz-box-shadow: 1px 5px 25px -17px #000;
}

.cardWrap {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 5px 25px -17px #000;
  -webkit-box-shadow: 1px 5px 25px -17px #000;
  -moz-box-shadow: 1px 5px 25px -17px #000;
}

.cardImage {
  display: block;
  border-radius: 10px;
  aspect-ratio: 1/1;
  transition: 0.6s;
}

.cardImage:hover {
  transition: 0.5s;
  transform: scale(1.2);
}

.cardTitle {
  width: 50%;
  position: absolute;
  display: block;
  color: #fff;
  font-size: var(--fs-300);
  font-weight: 600;
  left: 1rem;
  overflow: hidden;
  margin-bottom: 1rem;
  transition: 0.5s;
}

.cardTitle:hover {
  color: var(--main-color);
  transform: scale(1.05);
  transition: 0.5s;
}

/* small desktop version */
@media (max-width: 1000px) {
  .cardContainer {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 2rem;
    padding: 1rem;
    gap: 2rem;
  }
}

/* tablet version */
@media (max-width: 768px) {
  .cardContainer {
    margin-top: 1rem;
    padding: 1rem;
    gap: 1.5rem;
  }
  .cardTitle {
    font-size: 12px;
  }
}

/* phone version */
@media (max-width: 480px) {
  .cardContainer {
    width: 100%;
    margin-top: 1rem;
    background: none;
    border-radius: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 1rem;
    box-shadow: none;
  }
  .cardTitle {
    font-size: 13px;
  }
  .cardImage {
    aspect-ratio: 5/4;
  }
}
