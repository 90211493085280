.bannerContainer {
  position: relative;
  margin-bottom: 2rem;
}

.imageWrap,
.bannerImg {
  height: 220px;
  width: 100%;
}

.imageWrap {
  background-color: #000;
  border-radius: 25px;
}

.bannerImg {
  border-radius: 25px;
  opacity: 0.65;
  box-shadow: 1px 5px 25px -17px #000;
  -webkit-box-shadow: 1px 5px 25px -17px #000;
  -moz-box-shadow: 1px 5px 25px -17px #000;
}

.bannerTitle {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  color: #fff;
  font-size: var(--fs-700);
}

@media (max-width: 480px) {
  .bannerContainer {
    margin-bottom: 1rem;
  }

  .bannerTitle {
    position: absolute;
    text-align: left;
    font-size: 24px;
    line-height: 1.4;
    font-weight: 500;
    width: 90%;
  }

  .imageWrap,
  .bannerImg {
    height: 130px;
    width: 100%;
    border-radius: 10px;
  }
}

/* galaxy fold folded */
@media (max-width: 340px) {
  .bannerTitle {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
  }
}
